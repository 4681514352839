@use "@variables/breakpoints.scss";

@mixin up($size) {
  @if not map-has-key(breakpoints.$exports, $size) {
    @warn "'#{$size}' is not a valid size";
  }

  $breakpoint: map-get(breakpoints.$exports, $size);

  @media (min-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin down($size) {
  @if not map-has-key(breakpoints.$exports, $size) {
    @warn "'#{$size}' is not a valid size";
  }

  $breakpoint: map-get(breakpoints.$exports, $size);

  @media (max-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin between($start, $end) {
  @if not map-has-key(breakpoints.$exports, $start, $end) {
    @warn "'#{$start}' or '#{$end}' are not a valid sizes";
  }

  $breakpoint1: map-get(breakpoints.$exports, $start);
  $breakpoint2: map-get(breakpoints.$exports, $end);

  @media (min-width: #{$breakpoint1}px) and (max-width: #{$breakpoint2}px) {
    @content;
  }
}

